import { AUTH_TOKEN_KEYS, PUBLIC_ROUTES, ROUTES } from 'app/constants/app';

export function isPublicRoute(route: string) {
  return PUBLIC_ROUTES.reduce((acc, _route) => {
    return acc || route.includes(_route);
  }, false);
}

export function getCompanyOfficerToken(): string {
  const token = localStorage.getItem(AUTH_TOKEN_KEYS.companyOfficer);

  if (!token && !isPublicRoute(window.location.href)) {
    localStorage.clear();

    window.location.href = ROUTES.signIn;
  }

  return token ? `Bearer ${token}` : '';
}

/**
 * Gets ready-to-render products from a given order. It process basic and
 * variants-based orders.
 * @param order - The order to be processed.
 * @returns - An array of order products to render.
 */
export function getOrderProductsToRender(
  order: TOrder
): WithId<TOrderProductToRender>[] {
  return order.products.reduce((acc, product) => {
    if (product.variants && product.variants.length > 0) {
      return [
        ...acc,
        ...product.variants.map((variant) => {
          return {
            _id: product._id,
            qty: variant.qty,
            subtotal: variant.subtotal,
            image: product.image,
            price: variant.price,
            sku: variant.sku,
            name: product.name,
            category: product.category,
            mixinName: variant.mixinName,
          };
        }),
      ];
    }

    return [...acc, product as WithId<TOrderProductToRender>];
  }, [] as WithId<TOrderProductToRender>[]);
}
