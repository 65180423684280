import { MainContainer, Subheader, Paragraph } from './styles';

export default function SignCompanyTNCContent() {
  return (
    <MainContainer>
      <Subheader>Primero: Nombre de fantasía y comercial</Subheader>

      <Paragraph>
        GRAFITO. APP es el nombre de fantasía de la Razón Social M+R
        Comunicaciones EIRL, empresa debidamente constituida conforme a la
        Legislación Chilena.
      </Paragraph>

      <Subheader>Segundo: Objetivo de la app</Subheader>

      <Paragraph>
        GRAFITO. APP tiene la arquitectura para prestar servicio de Tienda
        Virtual, Monitoreo de Negocio en Línea y Plan de Comunicación, basado en
        una plataforma web y en un equipo multidisciplinario de profesionales.
        Esta herramienta busca enriquecer las aristas de comunicación y dar
        vitrina, apoyar y amplificar el negocio de MARCAS emprendedoras y micro
        empresas.
      </Paragraph>

      <Subheader>Tercero: Objeto del acuerdo</Subheader>

      <Paragraph>
        Mediante el presente documento, LA MARCA acepta las condiciones de
        GRAFITO. APP.
      </Paragraph>

      <Paragraph>
        Esta plataforma de comunicación y adquisición de productos o servicios,
        comprende la utilización de la APP a través de un portal web, que tendrá
        visible a clientes el nombre de LA MARCA usuaria.
      </Paragraph>

      <Subheader>Cuarto: Obligaciones de Grafito.app</Subheader>

      <Paragraph>
        Instalación de LA MARCA en la APP y acompañamiento en el manejo del
        Backoffice.
      </Paragraph>

      <Paragraph>
        El servicio podría ser interrumpido por GRAFITO. APP, por razones
        técnicas o de mantenimiento. Los trabajos que requieran pausa en el
        servicio, serán notificados con anticipación, siempre y cuando cuya
        ejecución no sean de urgencia. Los trabajos originados por
        circunstancias fortuitas o de fuerza mayor, no podrán ser notificados.
      </Paragraph>

      <Paragraph>
        Asistencia y soporte de lunes a viernes, de 9 a 17 hrs vía telefónica o
        WhatsApp.
      </Paragraph>

      <Paragraph>
        Mantener informado al cliente de las actualizaciones y nuevas versiones
        de la APP.
      </Paragraph>

      <Subheader>Quinto: Comisión y forma de pago</Subheader>

      <Paragraph>
        El servicio respaldado mediante el presente documento, consiste en el
        denominado PLAN BASE (no incluye precio compra del dominio) Costo: 1
        cuota mensual de 1UF y comisión de 5% menos el porcentaje de Transbank
        por cada venta, el cual sufre variaciones según compras con tarjetas
        bancarias de débito o crédito.
      </Paragraph>

      <Paragraph>
        Las transferencias de GRAFITO. APP de las ventas de cada MARCA, se harán
        según las fechas acordadas, caso a caso.
      </Paragraph>

      <Paragraph>
        GRAFITO. APP emitirá a LA MARCA, factura o boleta mensual por el
        porcentaje de comisión que corresponda al uso de la plataforma durante
        ese mes.
      </Paragraph>

      <Subheader>Sexto: Responsabilidad Tributaria</Subheader>

      <Paragraph>
        Es de exclusiva responsabilidad de LA MARCA emitir los documentos
        tributarios, facturas u boletas, a sus clientes que utilicen la Tienda
        Virtual. Por lo tanto, GRAFITO. APP no es responsable por el efectivo
        cumplimiento de la obligaciones tributarias o impositivas, establecidas
        por la ley vigente para cada venta efectuada por LA MARCA.
      </Paragraph>

      <Subheader>Séptimo: Despacho de productos</Subheader>

      <Paragraph>
        GRAFITO. APP no tiene rol en el despacho de productos y LA MARCA es la
        única responsable de la entrega y de dar solución a algún posible
        problema en el despacho o correcta entrega del producto al cliente
        final.
      </Paragraph>

      <Subheader>Octavo: Duración del servicio</Subheader>

      <Paragraph>
        Comenzará a partir del día UNO en que la Tienda Virtual de LA MARCA esté
        operativa y tendrá una duración inicial de 6 meses, con renovación
        automática mensual a partir del Séptimo mes, a menos que una de las
        partes notifique mediante mail su intención de no renovar, comunicado
        que deberá hacerse con una anticipación de 30 días.
      </Paragraph>

      <Subheader>Noveno: Limitaciones de garantía y responsabilidad</Subheader>

      <Paragraph>
        GRAFITO . APP no tendrá responsabilidad alguna por daños y perjuicios de
        cualquier naturaleza, sean directos o indirectos, previstos o
        imprevistos en la operatividad de la plataforma, en particular por los
        que puedan provenir de:
      </Paragraph>

      <Paragraph>
        Cualquier menoscabo, daño u otro deterioro que sufra la información que
        se suministra a través de la plataforma, por acción de agentes externos,
        ya sea persona natural o software, quien vulnere al sistema y sus
        dependencias.
      </Paragraph>

      <Paragraph>
        Caída del sistema ocasionado por fuerza mayor, caso fortuito, hecho de
        la naturaleza, actos vandálicos, incendios, y por otras causas no
        imputables a GRAFITO. APP
      </Paragraph>

      <Paragraph>
        Problemas de conexión, interrupción, suspensión o falla de la Red de
        internet, de Transbank o del Banco asociado a a la tarjeta usada en la
        compra.
      </Paragraph>

      <Paragraph>
        Mal uso del sistema por parte de LA MARCA o CLIENTE de la marca.
      </Paragraph>

      <Paragraph>
        Violación de LA MARCA sus dependientes o asesores de las normas
        relativas a la privacidad de las personas y usuarios, en especial la ley
        de protección de datos de carácter personal.
      </Paragraph>

      <Paragraph>
        Datos que sean entregados por terceras personas, links o sitios web de
        terceros, aún cuando estos sean proveídos por intermedio del sistema o
        mediante cualquier intercambio de datos a través de él.
      </Paragraph>

      <Subheader>Décimo: Propiedad intelectual</Subheader>

      <Paragraph>
        GRAFITO. APP, es creador y único dueño de todo el código fuente que
        forme parte de las soluciones y arquitectura de la plataforma.
      </Paragraph>

      <Subheader>Décimo primero: Seguridad de contraseña</Subheader>

      <Paragraph>
        La confidencialidad y calidad de las contraseñas que utiliza LA MARCA
        para ingresar a GRAFITO.APP son de exclusiva responsabilidad de cada
        marca. GRAFITO.APP no se hace responsable por accesos al Backoffice
        producto del uso indebido de la contraseña o derivados de elegir una
        contraseña de baja seguridad.
      </Paragraph>

      <Subheader>Décimo segundo: Contenidos visuales o escritos</Subheader>

      <Paragraph>
        GRAFITO. APP, no tiene autoría ni responsabilidad sobre el mal uso de
        los servicios de la plataforma por parte de LA MARCA o SUS CLIENTES,
        como tampoco es responsable de logos, fotografías, gráficas, artes,
        precios e información de cualquier naturaleza de la comunicación visual
        y escrita que publique LA MARCA en GRAFITO.APP
      </Paragraph>

      <Subheader>Décimo tercero: Actualización de condiciones</Subheader>

      <Paragraph>
        LA MARCA declara aceptar las presentes condiciones de trabajo mutuo y
        colaborativo. El actual instrumento podría en el tiempo variar sus
        condiciones, las cuales se comunicarán oportunamente por GRAFITO.APP
        para renovar este documento.
      </Paragraph>
    </MainContainer>
  );
}
