import { useContext } from 'react';
import { useFormik } from 'formik';
import { useMutation } from '@apollo/client';
import get from 'lodash/get';
import { Button, TextInput } from 'kosmos-ui';

import { UPDATE_MY_COMPANY_OFFICER_PW_MUTATION } from 'api/graphql/mutations/users/documents';
import { AlertsContext } from 'app/context';

import { formValidationSchema } from './helpers';
import { SectionContentContainer, ButtonsContainer } from '../styles';

function CompanyOfficerPasswordSettingsForm({
  hideDialog,
}: {
  hideDialog: () => void;
}) {
  const { showSuccessSnackbar, showErrorSnackbar } = useContext(AlertsContext);

  const [
    updateMyCompanyOfficerPasswordMutation,
    { loading: loadingUpdateMyCompanyOfficerPasswordMutation },
  ] = useMutation(UPDATE_MY_COMPANY_OFFICER_PW_MUTATION, {
    onCompleted() {
      showSuccessSnackbar({
        title: 'Contraseña actualizada',
        message: 'Tu contraseña fue actualizada correctamente!',
      });
    },
    onError(error) {
      showErrorSnackbar({
        title: 'Error actualizando contraseña',
        message: get(error, 'graphQLErrors[0].message'),
      });
    },
  });

  const form = useFormik({
    initialValues: {
      currentPw: '',
      newPw: '',
    },
    validationSchema: formValidationSchema,
    onSubmit(values) {
      updateMyCompanyOfficerPasswordMutation({
        variables: {
          currentPw: values.currentPw,
          newPw: values.newPw,
        },
      });
    },
  });

  return (
    <SectionContentContainer>
      <div style={{ width: '80%' }}>
        <p style={{ margin: '0 0 30px 0' }}>
          Tu contraseña debe tener mínimo 8 caracteres, al menos 1 letra
          mayúscula y 1 símbolo (@, $, !, %, *, ?, entre otros).
        </p>

        <TextInput
          value={form.values.currentPw}
          onChange={form.handleChange}
          label="Contraseña Actual"
          id="currentPw"
          name="currentPw"
          onBlur={form.handleBlur}
          style={{
            root: {
              width: '100%',
              marginBottom: '30px',
            },
          }}
          type="password"
          error={!!form.errors.currentPw && form.touched.currentPw}
        />

        <TextInput
          value={form.values.newPw}
          onChange={form.handleChange}
          label="Contraseña Nueva"
          id="newPw"
          name="newPw"
          onBlur={form.handleBlur}
          style={{
            root: {
              width: '100%',
            },
          }}
          type="password"
          error={!!form.errors.newPw && form.touched.newPw}
        />
      </div>

      <ButtonsContainer>
        <Button
          variant="secondary"
          onClick={hideDialog}
          style={{ marginRight: '15px' }}
        >
          Cancelar
        </Button>

        <Button
          variant="primary"
          onClick={() => form.handleSubmit()}
          loading={loadingUpdateMyCompanyOfficerPasswordMutation}
        >
          Guardar
        </Button>
      </ButtonsContainer>
    </SectionContentContainer>
  );
}

export default CompanyOfficerPasswordSettingsForm;
