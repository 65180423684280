import { createContext } from 'react';
import { useQuery } from '@apollo/client';
import { useHistory } from 'react-router';
import get from 'lodash/get';

import { GET_ME_AS_COMPANY_OFFICER_QUERY } from 'api/graphql/queries/users/company-officer/documents';

import { ICompanyOfficerContext, IProps } from './types';
import { ROUTES } from 'app/constants/app';

const CompanyOfficerContext = createContext({} as ICompanyOfficerContext);

function CompanyOfficerContextProvider({ children }: IProps) {
  const history = useHistory();

  const {
    data: meAsCompanyOfficerData,
    loading: loadingMeAsCompanyOfficerData,
    error: errorOnMeAsCompanyOfficerData,
    client: apolloClient,
  } = useQuery(GET_ME_AS_COMPANY_OFFICER_QUERY);

  function signOutCompanyOfficer(): void {
    localStorage.clear();

    apolloClient.clearStore();

    history.push(ROUTES.signIn);
  }

  const companyOfficerProfile =
    loadingMeAsCompanyOfficerData || errorOnMeAsCompanyOfficerData
      ? {}
      : get(meAsCompanyOfficerData, 'meAsCompanyOfficer');

  return (
    <CompanyOfficerContext.Provider
      value={{
        signOutCompanyOfficer,
        ...companyOfficerProfile,
      }}
    >
      {children}
    </CompanyOfficerContext.Provider>
  );
}

export { CompanyOfficerContext, CompanyOfficerContextProvider };
