import { SectionedDialog } from 'kosmos-ui';

import CompanyOfficerProfileSettingsForm from '../CompanyOfficerProfileSettingsForm';
import CompanyOfficerPasswordSettingsForm from '../CompanyOfficerPasswordSettingsForm';

import { IProps } from './types';

function SettingsCompanyOfficerDialog({ show, setShow, profile }: IProps) {
  return (
    <SectionedDialog
      show={show}
      setShow={() => setShow(false)}
      header="Ajustes"
      sections={[
        {
          header: 'Mi Perfil',
          content: (
            <CompanyOfficerProfileSettingsForm
              profile={profile}
              hideDialog={() => setShow(false)}
            />
          ),
        },
        {
          header: 'Contraseña',
          content: (
            <CompanyOfficerPasswordSettingsForm
              hideDialog={() => setShow(false)}
            />
          ),
        },
      ]}
    />
  );
}

export default SettingsCompanyOfficerDialog;
