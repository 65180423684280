import { useMutation } from '@apollo/client';

import { UPDATE_COMPANY_MUTATION } from './documents';
import {
  TUpdateCompanyData,
  TUpdateCompanyVariables,
  TUpdateCompanyOptions,
} from './types';

export function useUpdateCompanyMutation(options?: TUpdateCompanyOptions) {
  const mutation = useMutation<TUpdateCompanyData, TUpdateCompanyVariables>(
    UPDATE_COMPANY_MUTATION,
    options
  );

  return mutation;
}
