import { useQuery } from '@apollo/client';

import { GET_COMPANY_QUERY } from './documents';
import { TGetCompanyData, TGetCompanyOptions } from './types';

export function useGetCompanyQuery(options?: TGetCompanyOptions) {
  const query = useQuery<TGetCompanyData>(GET_COMPANY_QUERY, options);

  return query;
}
