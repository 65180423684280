import { ReactNode } from 'react';
import { ThemeProvider, createGlobalStyle } from 'styled-components';
import { theme } from 'kosmos-ui';

const GlobalStyle = createGlobalStyle`
  p {
    line-height: 25px;
    margin: 0;
    font-size: ${theme.fontSizes.regular};
    color: ${theme.colorsPalette.gray.default};
  }

  h1,h2,h3,h4 {
    color: ${theme.colorsPalette.black.default};
  }
`;

function ThemeContextProvider({ children }: { children: ReactNode }) {
  return (
    <ThemeProvider theme={theme}>
      <>
        <GlobalStyle />

        {children}
      </>
    </ThemeProvider>
  );
}

export default ThemeContextProvider;
