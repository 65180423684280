import { gql } from '@apollo/client';

export const SIGN_COMPANY_TNC_MUTATION = gql`
  mutation SIGN_COMPANY_TNC_MUTATION($signer: String!) {
    signCompanyTNC(signer: $signer) {
      message
    }
  }
`;

export const UPDATE_COMPANY_MUTATION = gql`
  mutation UPDATE_COMPANY_MUTATION(
    $_id: ID
    $address1: String
    $address2: String
    $apps: CompanyAppsInput
    $email1: String
    $email2: String
    $fantasyName: String
    $facebook: String
    $hasFirstSignIn: Boolean
    $instagram: String
    $logo: Upload
    $name: String
    $phone1: String
    $phone2: String
  ) {
    updateCompany(
      _id: $_id
      address1: $address1
      address2: $address2
      apps: $apps
      email1: $email1
      email2: $email2
      fantasyName: $fantasyName
      facebook: $facebook
      hasFirstSignIn: $hasFirstSignIn
      instagram: $instagram
      logo: $logo
      name: $name
      phone1: $phone1
      phone2: $phone2
    ) {
      _id
      address1
      address2
      apps {
        ga {
          trackingId
          tmId
        }
      }
      email1
      email2
      fantasyName
      facebook
      hasFirstSignIn
      instagram
      logo
      name
      phone1
      phone2
    }
  }
`;
