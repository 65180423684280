import { createContext, ReactNode, useEffect } from 'react';

import { useGetCompanyQuery } from 'api/graphql/queries/companies/hooks';
import { useUpdateCompanyMutation } from 'api/graphql/mutations/company/hooks';
import SignCompanyTNCDialog from './components/SignCompanyTNCDialog';

//import { getCompanyContextValues } from './helpers';
// import { DEFAULT_COMPANY_CONTEXT_VALUES } from './constants';
import { TCompanyContextValues } from './types';

export const CompanyContext = createContext({} as TCompanyContextValues);

/**
 * Provides to the app the current company info as React context.
 * @param children Tree of components to inject the company data.
 */
export function CompanyContextProvider({ children }: { children: ReactNode }) {
  const { data: companyData } = useGetCompanyQuery();

  const [updateCompanyMutation] = useUpdateCompanyMutation();

  const company = companyData?.getCompany;

  useEffect(() => {
    if (company && !company?.hasFirstSignIn)
      updateCompanyMutation({
        variables: { hasFirstSignIn: true },
      });
  }, [company, company?.hasFirstSignIn, company?._id, updateCompanyMutation]);

  if (!company) return null;

  return (
    <>
      <CompanyContext.Provider value={company}>
        {children}
      </CompanyContext.Provider>

      {!(company?.tnc && company?.tnc.signed) && <SignCompanyTNCDialog />}
    </>
  );
}
