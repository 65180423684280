import styled from 'styled-components';

import { TSectionContainerProps } from './types';

export const SidebarContainer = styled.aside<{ show: boolean }>`
  position: fixed;
  display: flex;
  flex-direction: column;
  padding: 80px 24px;
  background-color: ${({ theme }) => theme.colorsPalette.white.default};
  height: 100vh;
  min-width: 200px;
  max-width: 300px;
  z-index: 2;
  box-shadow: 0px 12px 0px ${({ theme }) => theme.colorsPalette.black.default};
  left: 0;
  transform: ${({ show }) => (!show ? 'translateX(-100%)' : 'translateX(0)')};
  transition: transform 0.3s ease-out;
`;

export const SidebarSectionContainer = styled.div<TSectionContainerProps>`
  display: flex;
  align-items: center;
  margin: 0 -8px;
  border-radius: 8px;
  padding: 16px;
  cursor: pointer;
  color: ${({ theme, isSelected }) =>
    isSelected
      ? theme.colorsPalette.blue.default
      : theme.colorsPalette.gray.dark};
  font-weight: ${({ isSelected }) => (isSelected ? 600 : 400)};
  transition: background-color 0.5s;
  background-color: ${({ theme, isSelected }) =>
    isSelected
      ? theme.colorsPalette.blue.lighter
      : theme.colorsPalette.white.default};

  ${({ subsectionsActive }) => {
    if (subsectionsActive) {
      return `
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
    `;
    }
  }}

  &:not(:last-child) {
    margin-bottom: 8px;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colorsPalette.blue.lighter};
  }
`;

export const SidebarIconLabelContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const SidebarSubsectionContainer = styled.div<{ isSelected: boolean }>`
  font-size: ${({ theme }) => theme.fontSizes.regular};
  color: ${({ theme, isSelected }) =>
    isSelected
      ? theme.colorsPalette.blue.default
      : theme.colorsPalette.gray.default};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  margin: 16px 0 0 36px;
  transition: color 0.3s;

  &:hover {
    color: ${({ theme }) => theme.colorsPalette.blue.default};
  }
`;

export const Paragraph = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.regular};
  margin-left: 16px;
  color: inherit;
`;
