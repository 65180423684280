import { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useApolloClient, useMutation } from '@apollo/client';
import { useFormik } from 'formik';
import { Button, Dialog, TextInput } from 'kosmos-ui/lib';
import get from 'lodash/get';

import { SIGN_COMPANY_TNC_MUTATION } from 'api/graphql/mutations/company/documents';
import { ROUTES } from 'app/constants/app';
import { AlertsContext } from 'app/context/Alerts';
import SignCompanyTNCContent from '../SignCompanyTNCContent';

import { formValidationSchema } from './helpers';

export default function SignCompanyTNCDialog() {
  const { showErrorSnackbar, showSuccessSnackbar } = useContext(AlertsContext);

  const [show, setShow] = useState(true);

  const history = useHistory();

  const apolloClient = useApolloClient();

  const [signCompanyTNCMutation, { loading: loadingSignCompanyTNCMutation }] =
    useMutation(SIGN_COMPANY_TNC_MUTATION, {
      onCompleted(res) {
        if (!get(res, 'signCompanyTNC.message')) {
          showErrorSnackbar({
            message: 'Error firmando términos y condiciones de uso',
          });
        } else {
          showSuccessSnackbar({
            message: 'Términos y condiciones firmados correctamente!',
          });

          setShow(false);
        }
      },
      onError(error) {
        showErrorSnackbar({
          title: 'Error firmando términos y condiciones de uso',
          message: get(error, 'graphQLErrors[0].message'),
        });
      },
    });

  const form = useFormik({
    initialValues: { signer: '' },
    validationSchema: formValidationSchema,
    onSubmit(values) {
      signCompanyTNCMutation({ variables: { signer: values.signer } });
    },
  });

  function handleDeclineAction() {
    localStorage.clear();

    apolloClient.clearStore();

    history.push(ROUTES.signIn);
  }

  return (
    <Dialog show={show} style={{ width: '40vw' }}>
      <Dialog.Header>
        <Dialog.Title>Términos y condiciones de uso</Dialog.Title>

        <Dialog.Close onClick={handleDeclineAction} />
      </Dialog.Header>

      <Dialog.Body>
        <SignCompanyTNCContent />

        <TextInput
          value={form.values.signer}
          onChange={form.handleChange}
          label="Firmante"
          id="signer"
          name="signer"
          onBlur={form.handleBlur}
          style={{
            root: { margin: '16px 0', width: '50%' },
          }}
          error={!!form.errors.signer && form.touched.signer}
        />
      </Dialog.Body>

      <Dialog.Footer>
        <Button
          variant="secondary"
          format="outline"
          onClick={handleDeclineAction}
          loading={loadingSignCompanyTNCMutation}
          style={{ marginRight: 8 }}
        >
          No acepto
        </Button>

        <Button
          variant="primary"
          onClick={() => form.handleSubmit()}
          loading={loadingSignCompanyTNCMutation}
        >
          Acepto
        </Button>
      </Dialog.Footer>
    </Dialog>
  );
}
