import {
  ProfilesIcon,
  DeliveryIcon,
  BarChartIcon,
  ListIcon,
  ProductLabelsIcon,
  StoreIcon,
  CouponIcon,
  AppsIcon,
  CardIcon,
} from 'kosmos-ui';

import { APP_SECTION_NAMES, ROUTES } from 'app/constants/app';

import { TSidebarSection } from './types';

export const SIDEBAR_SECTIONS: TSidebarSection[] = [
  {
    _id: 'dashboard',
    route: ROUTES.dashboard,
    label: APP_SECTION_NAMES.dashboard,
    icon: BarChartIcon,
  },
  {
    _id: 'products',
    route: ROUTES.products,
    label: APP_SECTION_NAMES.products,
    icon: ProductLabelsIcon,
  },
  {
    _id: 'orders',
    route: ROUTES.orders,
    label: APP_SECTION_NAMES.orders,
    icon: ListIcon,
  },
  {
    _id: 'shoppers',
    route: ROUTES.shoppers,
    label: APP_SECTION_NAMES.shoppers,
    icon: ProfilesIcon,
  },
  {
    _id: 'coupons',
    route: ROUTES.coupons,
    label: APP_SECTION_NAMES.coupons,
    icon: CouponIcon,
  },
  {
    _id: 'delivery',
    route: ROUTES.delivery,
    label: APP_SECTION_NAMES.delivery,
    icon: DeliveryIcon,
  },
  {
    _id: 'apps',
    route: ROUTES.apps,
    label: APP_SECTION_NAMES.apps,
    icon: AppsIcon,
  },
  {
    _id: 'os',
    label: APP_SECTION_NAMES.os,
    icon: StoreIcon,
    subsections: [
      {
        _id: 'osThemeContent',
        route: ROUTES.osThemeContent,
        label: APP_SECTION_NAMES.osThemeContent,
      },
      {
        _id: 'osPages',
        route: ROUTES.osPages,
        label: APP_SECTION_NAMES.osPages,
      },
      {
        _id: 'osNavlinks',
        route: ROUTES.osNavlinks,
        label: APP_SECTION_NAMES.osNavlinks,
      },
      {
        _id: 'osLocales',
        route: ROUTES.osLocales,
        label: APP_SECTION_NAMES.osLocales,
      },
    ],
  },
  {
    _id: 'billingAndPlans',
    route: ROUTES.billingAndPlans,
    label: APP_SECTION_NAMES.billingAndPlans,
    icon: CardIcon,
  },
];
