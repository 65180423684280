import styled from "styled-components";

const MainContainer = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 1;
  top: 0;
`;

export { MainContainer };
