export const ROUTES: Record<TRoute, string> = {
  signIn: '/signin',
  dashboard: '/dashboard',
  products: '/products',
  orders: '/orders',
  delivery: '/delivery',
  shoppers: '/shoppers',
  coupons: '/coupons',
  apps: '/apps',
  osThemeContent: '/online-store-theme',
  osPages: '/online-store-pages',
  osNavlinks: '/online-store-navlinks',
  osLocales: '/online-store-locales',
  billingAndPlans: '/billing',
  createPaymentMethodResult: '/billing/paymentMethods/create',
};

export const OS_ROUTES: Record<TOSRoute, string> = {
  home: '/',
  productDetails: '/product/',
  productCategories: '/categories',
  productMerchants: '/merchants',
  pages: '/custom-pages',
  checkout: '/checkout',
};

export const PUBLIC_ROUTES = [ROUTES.signIn];

export const APP_SECTION_NAMES: Record<TAppSection, string> = {
  signIn: 'Login',
  dashboard: 'Dashboard',
  products: 'Productos',
  orders: 'Ordenes',
  delivery: 'Delivery',
  shoppers: 'Clientes',
  coupons: 'Descuentos',
  apps: 'Apps',
  os: 'Tienda virtual',
  osThemeContent: 'Apariencia',
  osPages: 'Páginas',
  osNavlinks: 'Navegación',
  osLocales: 'Lenguajes',
  billingAndPlans: 'Facturación y Planes',
  createPaymentMethodResult: 'Facturación y Planes',
};

export const LINKS_AND_ROUTES = Object.entries(ROUTES).reduce(
  (acc, [key, value]) => {
    return { ...acc, [value as string]: key as TRoute };
  },
  {} as Record<string, TAppSection>
);

export const AUTH_TOKEN_KEYS: Record<TAuthTokenEntity, string> = {
  companyOfficer: 'GrafitoAppCompanyOfficerAuthToken',
};

export const SEARCHABLE_SECTIONS: TAppSection[] = ['products', 'shoppers'];

export const TIME_UNIT_NAMES: Record<TTimeUnit, string> = {
  day: 'día',
  week: 'semana',
  month: 'mes',
  semester: 'semestre',
  year: 'año',
  all: 'todo',
};
