import { createContext, ReactNode, useEffect, useState } from 'react';

import useDebounce from 'app/hooks';

import { TSearchContext } from './types';
import { SEARCH_DEBOUNCE_DELAY } from './constants';

export const SearchContext = createContext({} as TSearchContext);

export function SearchContextProvider({ children }: { children: ReactNode }) {
  const [term, setTerm] = useState('');
  const [onSearch, setOnSearch] = useState(false);

  const debouncedTerm = useDebounce(term, SEARCH_DEBOUNCE_DELAY);

  useEffect(() => {
    if (term) setOnSearch(true);
  }, [term]);

  useEffect(() => {
    if (debouncedTerm) setOnSearch(false);
  }, [debouncedTerm]);

  return (
    <SearchContext.Provider value={{ term, debouncedTerm, onSearch, setTerm }}>
      {children}
    </SearchContext.Provider>
  );
}
