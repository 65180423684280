import { useState } from 'react';

import Backdrop from './Backdrop';
import Sidebar from './Sidebar';
import Topbar from './Topbar';

/**
 * Renders app main navigation bar.
 */
function Navbar() {
  const [showSidebar, setShowSidebar] = useState(false);

  function toggleShowSidebar() {
    setShowSidebar(!showSidebar);
  }

  return (
    <>
      <Topbar toggleShowSidebar={toggleShowSidebar} />

      <Sidebar show={showSidebar} toggleShowSidebar={toggleShowSidebar} />

      {showSidebar && <Backdrop toggleShowSidebar={toggleShowSidebar} />}
    </>
  );
}

export default Navbar;
