import { ApolloProvider } from '@apollo/client';

import apolloClient from '../api/graphql/client';
import Routes from './routes';
import {
  AlertsContextProvider,
  LayoutContextProvider,
  ThemeContextProvider,
} from './context';
import { SearchContextProvider } from './context/Search';

export default function App() {
  return (
    <ApolloProvider client={apolloClient}>
      <ThemeContextProvider>
        <LayoutContextProvider>
          <AlertsContextProvider>
            <SearchContextProvider>
              <Routes />
            </SearchContextProvider>
          </AlertsContextProvider>
        </LayoutContextProvider>
      </ThemeContextProvider>
    </ApolloProvider>
  );
}
