import { gql } from '@apollo/client';

export const GET_ME_AS_COMPANY_OFFICER_QUERY = gql`
  query GET_ME_AS_COMPANY_OFFICER_QUERY {
    meAsCompanyOfficer {
      _id
      companyId
      firstname
      lastname
      email
      avatar
    }
  }
`;
