import { useContext, useState } from 'react';
import {
  Dropdown,
  TextInput,
  HamburgerIcon,
  SearchIcon,
  theme,
  Avatar,
} from 'kosmos-ui';

import { CompanyOfficerContext } from 'app/context/CompanyOfficer';
import { useAppSection } from 'app/hooks/app';
import { useSearch } from 'app/context/Search/hooks';
import SettingsCompanyOfficerDialog from './components/SettingsCompanyOfficerDialog';
import { APP_SECTION_NAMES, SEARCHABLE_SECTIONS } from 'app/constants/app';

import { TProps } from './types';
import {
  ActiveSectionHeader,
  BarContainer,
  MainContainer,
  ToggleSidebarButton,
  Paragraph,
  EmailParagraph,
} from './styles';

export default function Topbar({ toggleShowSidebar }: TProps) {
  const { _id, avatar, firstname, lastname, email, signOutCompanyOfficer } =
    useContext(CompanyOfficerContext);

  const search = useSearch();

  const [
    showCompanyOfficerSettingsDialog,
    setShowCompanyOfficerSettingsDialog,
  ] = useState(false);

  const [activeSection] = useAppSection();

  return (
    <MainContainer>
      <BarContainer>
        <div style={{ display: 'flex' }}>
          <ToggleSidebarButton onClick={toggleShowSidebar}>
            <HamburgerIcon height="20px" />
          </ToggleSidebarButton>

          {activeSection && (
            <ActiveSectionHeader>
              {APP_SECTION_NAMES[activeSection]}
            </ActiveSectionHeader>
          )}
        </div>

        <div style={{ display: 'flex' }}>
          {activeSection && SEARCHABLE_SECTIONS.includes(activeSection) && (
            <TextInput
              value={search.term}
              onChange={(event) => search.setTerm(event.target.value)}
              style={{ root: { marginRight: 16 } }}
              placeholder={`Buscar en ${APP_SECTION_NAMES[
                activeSection
              ].toLowerCase()}...`}
              variant="filled"
              adornments={{ left: <SearchIcon height="18px" /> }}
            />
          )}

          <Dropdown>
            <Dropdown.ToggleButton>
              <Avatar src={avatar} withInitials>
                {firstname} {lastname}
              </Avatar>
            </Dropdown.ToggleButton>

            <Dropdown.Menu style={{ zIndex: 3 }}>
              <Dropdown.Item separator>
                <Paragraph style={{ fontWeight: theme.fontWeights.bold }}>
                  {firstname} {lastname}
                </Paragraph>

                <EmailParagraph>{email}</EmailParagraph>
              </Dropdown.Item>

              <Dropdown.Item
                default
                onClick={() => setShowCompanyOfficerSettingsDialog(true)}
              >
                Ajustes
              </Dropdown.Item>

              <Dropdown.Item default onClick={() => signOutCompanyOfficer()}>
                Cerrar sesión
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </BarContainer>

      <SettingsCompanyOfficerDialog
        show={showCompanyOfficerSettingsDialog}
        setShow={setShowCompanyOfficerSettingsDialog}
        profile={{ _id, avatar, firstname, lastname, email }}
      />
    </MainContainer>
  );
}
