import * as yup from 'yup';

const formValidationSchema = yup.object().shape({
  currentPw: yup.string().required('Por favor ingresa tu actual contraseña.'),
  newPw: yup
    .string()
    .required('Por favor ingresa tu nueva contraseña.')
    .min(8, 'Tu contraseña debe tener mínimo 8 caracteres.')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      'Tu contraseña debe contener una mayuscula y símbolo.'
    ),
});

export { formValidationSchema };
