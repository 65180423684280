import { gql } from '@apollo/client';

export const GET_COMPANY_QUERY = gql`
  query GET_COMPANY_QUERY {
    getCompany {
      _id
      address1
      address2
      apps {
        ga {
          trackingId
          tmId
        }
      }
      email1
      email2
      fantasyName
      hasFirstSignIn
      facebook
      instagram
      logo
      name
      phone1
      phone2
      tnc {
        signed
      }
    }
  }
`;
