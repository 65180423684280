import { useEffect, useState, Dispatch, SetStateAction } from 'react';
import { useRouteMatch } from 'react-router-dom';

import { LINKS_AND_ROUTES } from 'app/constants/app';

export function useAppSection(): [
  TAppSection | undefined,
  Dispatch<SetStateAction<TAppSection | undefined>>
] {
  const match = useRouteMatch();

  const [activeSection, setActiveSection] = useState<TAppSection | undefined>(
    undefined
  );

  useEffect(() => {
    setActiveSection(LINKS_AND_ROUTES[match.path]);
  }, [match.path]);

  return [activeSection, setActiveSection];
}
