import { theme } from 'kosmos-ui/lib';
import { TVectorProps } from 'kosmos-ui/lib/types/vectors';

function getSidebarIconColors(
  iconIsActive: boolean
): Required<Pick<TVectorProps, 'colors' | 'color'>> {
  return {
    colors: {
      default: iconIsActive
        ? theme.colorsPalette.blue.default
        : theme.colorsPalette.gray.light,
      light: iconIsActive
        ? theme.colorsPalette.blue.light
        : theme.colorsPalette.gray.lighter,
    },
    color: iconIsActive
      ? theme.colorsPalette.blue.default
      : theme.colorsPalette.gray.light,
  };
}

export { getSidebarIconColors };
