import { gql } from '@apollo/client';

export const SIGN_IN_COMPANY_OFFICER_MUTATION = gql`
  mutation SIGN_IN_COMPANY_OFFICER_MUTATION(
    $email: String!
    $password: String!
  ) {
    signInCompanyOfficer(email: $email, password: $password) {
      token
    }
  }
`;

export const UPDATE_MY_COMPANY_OFFICER_PROFILE_MUTATION = gql`
  mutation UPDATE_MY_COMPANY_OFFICER_PROFILE_MUTATION(
    $firstname: String
    $lastname: String
    $avatar: Upload
  ) {
    updateMyCompanyOfficerProfile(
      firstname: $firstname
      lastname: $lastname
      avatar: $avatar
    ) {
      _id
      firstname
      lastname
      avatar
    }
  }
`;

export const UPDATE_MY_COMPANY_OFFICER_PW_MUTATION = gql`
  mutation UPDATE_MY_COMPANY_OFFICER_PW_MUTATION(
    $currentPw: String!
    $newPw: String!
  ) {
    updateMyCompanyOfficerPw(currentPw: $currentPw, newPw: $newPw) {
      message
    }
  }
`;
