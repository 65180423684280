export const NON_VALID_CHARS = 'ÃÀÁÄÂÈÉËÊÌÍÏÎÒÓÖÔÙÚÜÛãàáäâèéëêìíïîòóöôùúüûÑñÇç';

export const VALID_CHARS = 'AAAAAEEEEIIIIOOOOUUUUaaaaaeeeeiiiioooouuuunncc';

export const CHARS_EQUIVALENCE_HASH_TABLE: {
  [key: string]: string;
} = [...NON_VALID_CHARS].reduce((acc, nonValidChar, index) => {
  return { ...acc, [nonValidChar]: [...VALID_CHARS][index] };
}, {} as { [key: string]: string });

export const IMGS_MAX_SIZES_IN_BYTES = {
  THUMB: 15000000,
  BACKGROUND: 15000000,
  WALLPAPER: 15000000,
};

export const CSV_MAX_FILE_SIZE_IN_BYTES = 50000000;

export const ORDER_STATUS_DISPLAY_NAMES: { [key: string]: string } = {
  queued: 'Pendiente',
  preparing: 'Preparando',
  delivering: 'Entregando',
  done: 'Entregada',
  canceled: 'Cancelada',
};

export const WEEK_DAYS: string[] = [
  'Lunes',
  'Martes',
  'Miercoles',
  'Jueves',
  'Viernes',
  'Sabado',
  'Domingo',
];

export const ONLINE_STORE_PAGE_TYPE_NAMES_HASH_TABLE: Record<
  TOSPageType,
  string
> = {
  blog: 'Blog',
  gallery: 'Galería',
};

export const ONLINE_STORE_FONT_FAMILIES_HASH_TABLE: {
  [key: string]: { name: string; weights: string[] };
} = {
  "'Abril Fatface', cursive": {
    name: 'Abril Fatface',
    weights: ['400'],
  },
  "'Amatic SC', cursive": {
    name: 'Amatic SC',
    weights: ['400', '700'],
  },
  "'Bungee', cursive": {
    name: 'Bungee',
    weights: ['400'],
  },
  "'Comfortaa', cursive": {
    name: 'Comfortaa',
    weights: ['300', '400', '600', '700'],
  },
  "'DM Sans', sans-serif": {
    name: 'DM Sans',
    weights: ['400', '700'],
  },
  "'Lato', sans-serif": {
    name: 'Lato',
    weights: ['300', '400', '700', '900'],
  },
  "'Lobster', cursive": {
    name: 'Lobster',
    weights: ['400'],
  },
  "'Montserrat', sans-serif": {
    name: 'Montserrat',
    weights: ['300', '400', '600', '700', '800', '900'],
  },
  "'Nunito Sans', sans-serif": {
    name: 'Nunito Sans',
    weights: ['300', '400', '600', '700', '800', '900'],
  },
  "'Open Sans', sans-serif": {
    name: 'Open Sans',
    weights: ['300', '400', '600', '700', '800'],
  },
  "'Pacifico', cursive": {
    name: 'Pacifico',
    weights: ['400'],
  },
  "'Palanquin', sans-serif": {
    name: 'Palanquin',
    weights: ['400'],
  },
  "'Raleway', sans-serif": {
    name: 'Raleway',
    weights: ['300', '400', '600', '700', '800', '900'],
  },
  "'Roboto', sans-serif": {
    name: 'Roboto',
    weights: ['300', '400', '700', '900'],
  },
  "'Rock Salt', cursive": {
    name: 'Rock Salt',
    weights: ['400'],
  },
  "'Ultra', serif": {
    name: 'Ultra',
    weights: ['400'],
  },
};

export const OS_FONT_WEIGHTS: { [key: string]: string } = {
  '200': 'Extra light',
  '300': 'Light',
  '400': 'Regular',
  '600': 'Semi bold',
  '700': 'Bold',
  '800': 'Extra bold',
  '900': 'Black',
};
