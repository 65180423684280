import styled from 'styled-components';

const MainContainer = styled.header`
  position: fixed;
  width: 100%;
  z-index: 3;
`;

const BarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 36px;
  height: 50px;
  color: ${({ theme }) => theme.colorsPalette.black.default};
  box-shadow: 0px 0px 14px -11px ${({ theme }) => theme.colorsPalette.black.default};
  background-color: ${({ theme }) => theme.colorsPalette.white.default};
  transition: background-color 0.5s, box-shadow 0.5s;
`;

const ActiveSectionHeader = styled.h1`
  margin: 0 16px;
  font-size: 16px;
  color: ${({ theme }) => theme.colorsPalette.gray.dark};
  font-weight: ${({ theme }) => theme.fontWeights.bolder};
`;

const ToggleSidebarButton = styled.button`
  padding: 0;
  background: none;
  border: none;
  color: ${({ theme }) => theme.colorsPalette.blue.default};

  &:active {
    outline: none;
  }

  &:focus {
    outline: none;
  }
`;

const Paragraph = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.regular};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
`;

const EmailParagraph = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.regular};
  margin: 0;
  color: ${({ theme }) => theme.colorsPalette.gray.default};
`;

export {
  ActiveSectionHeader,
  BarContainer,
  MainContainer,
  ToggleSidebarButton,
  EmailParagraph,
  Paragraph,
};
