import { Suspense, lazy } from 'react';
import {
  Switch,
  Route,
  Redirect,
  BrowserRouter as Router,
} from 'react-router-dom';
import { FallbackScreen } from 'kosmos-ui';

import Navbar from 'app/sections/private/Navbar';
import { CompanyContextProvider } from 'app/context';
import { CompanyOfficerContextProvider } from 'app/context/CompanyOfficer';
import { getCompanyOfficerToken } from 'app/helpers/app';
import { ROUTES } from 'app/constants/app';
import { useBackgroundLocation } from 'app/hooks/routes';

const SignIn = lazy(() => import('../sections/public/SignIn'));
const Dashboard = lazy(() => import('../sections/private/Dashboard'));
const ProductsSection = lazy(() => import('../sections/private/Products'));
const OrdersSection = lazy(() => import('app/sections/private/Orders'));
const ShoppersSection = lazy(() => import('../sections/private/Shoppers'));
const CouponsSection = lazy(() => import('../sections/private/Coupons'));
const DeliverySection = lazy(() => import('app/sections/private/Delivery'));
const BillingAndPlansSection = lazy(
  () => import('app/sections/private/BillingAndPlans')
);
const AppsSection = lazy(() => import('app/sections/private/Apps'));
const OSThemeAndContent = lazy(
  () => import('../sections/private/online-store/OSThemeAndContent')
);
const OSPages = lazy(() => import('app/sections/private/online-store/OSPages'));
const OSNavlinks = lazy(
  () => import('app/sections/private/online-store/OSNavlinks')
);
const OSLocales = lazy(
  () => import('app/sections/private/online-store/OSLocales')
);

export default function Routes() {
  const companyOfficerToken = getCompanyOfficerToken();

  return (
    <Router>
      <Suspense fallback={<FallbackScreen type='loading' />}>
        <Switch>
          <Route path={ROUTES.signIn} component={SignIn} />

          <Route
            path={[
              ROUTES.dashboard,
              ROUTES.products,
              ROUTES.orders,
              ROUTES.shoppers,
              ROUTES.coupons,
              ROUTES.delivery,
              ROUTES.apps,
              ROUTES.osThemeContent,
              ROUTES.osPages,
              ROUTES.osNavlinks,
              ROUTES.osLocales,
              ROUTES.billingAndPlans,
            ]}
            component={PrivateRoutes}
          />

          <Redirect
            to={companyOfficerToken ? ROUTES.dashboard : ROUTES.signIn}
          />
        </Switch>
      </Suspense>
    </Router>
  );
}

function PrivateRoutes() {
  const { location, locationBackground } = useBackgroundLocation();

  const companyOfficerToken = getCompanyOfficerToken();

  if (!companyOfficerToken) return null;

  return (
    <CompanyContextProvider>
      <CompanyOfficerContextProvider>
        <Navbar />

        <Suspense fallback={<FallbackScreen type='loading' />}>
          <Switch location={locationBackground || location}>
            <Route path={ROUTES.dashboard} component={Dashboard} />

            <Route path={ROUTES.products} component={ProductsSection} />

            <Route path={ROUTES.orders} component={OrdersSection} />

            <Route path={ROUTES.shoppers} component={ShoppersSection} />

            <Route path={ROUTES.coupons} component={CouponsSection} />

            <Route path={ROUTES.delivery} component={DeliverySection} />

            <Route path={ROUTES.apps} component={AppsSection} />

            <Route path={ROUTES.osThemeContent} component={OSThemeAndContent} />

            <Route path={ROUTES.osPages} component={OSPages} />

            <Route path={ROUTES.osNavlinks} component={OSNavlinks} />

            <Route path={ROUTES.osLocales} component={OSLocales} />

            <Route
              exact
              path={[
                ROUTES.billingAndPlans,
                `${ROUTES.createPaymentMethodResult}/:resultToken`,
              ]}
              component={BillingAndPlansSection}
            />
          </Switch>
        </Suspense>
      </CompanyOfficerContextProvider>
    </CompanyContextProvider>
  );
}
