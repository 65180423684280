import {
  useEffect,
  useRef,
  useState,
  MutableRefObject,
  MouseEvent,
} from 'react';

/**
 * Keeps track of a React state previous value once it changes.
 * @param value React state variable.
 * @returns The previous value of the state provided param.
 */
export function usePrevious(value: any) {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  });

  return ref.current;
}

/**
 * Check if a React state has changed or not.
 * @param value React state variable.
 * @returns A flag indicating if the provided state value has changed.
 */
export function useCompare(value: any) {
  const prevValue = usePrevious(value);

  return prevValue !== value;
}

/**
 * Alert clicks outside of the passed ref.
 * @param ref React ref to which the click outside will be measured.
 * @param callback Callback to execute once the click outside happens (optional).
 * @returns A flag indicating if the user clicked or not outside the passed ref component.
 */
export function useClickOutsideContainer(
  ref: MutableRefObject<any>,
  callback?: () => void
) {
  const [clickedOutside, setClickedOutside] = useState(false);

  useEffect(() => {
    function handleClickOutside(
      event: MouseEvent<HTMLButtonElement, MouseEvent>
    ) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback && callback();

        setClickedOutside(true);
      }
    }

    //@ts-ignore
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      //@ts-ignore
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, clickedOutside, callback]);

  return clickedOutside;
}

/**
 * Sets a debounced value to value (passed in) after the specified delay.
 * @param value - Value to debounce.
 * @param delay - Timeout to delay the value setup.
 */
export default function useDebounce<T = string>(value: T, delay: number): T {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}
