import * as yup from "yup";

const formValidationSchema = yup.object().shape({
  email: yup
    .string()
    .required("Tu perfil debe tener un email asociado.")
    .email("El email ingresado no es válido.")
});

export { formValidationSchema };
