import { createContext, useState, useEffect, ReactNode } from 'react';

import { IProps } from './types';

const LayoutContext = createContext({
  mobile: undefined,
} as IProps);

function LayoutContextProvider({ children }: { children: ReactNode }) {
  const [mobile, setMobile] = useState(window.innerWidth <= 600);

  useEffect(() => {
    const handleWindowResize = () => setMobile(window.innerWidth <= 600);

    window.addEventListener('resize', handleWindowResize);

    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  return (
    <LayoutContext.Provider value={{ mobile }}>
      {children}
    </LayoutContext.Provider>
  );
}

export { LayoutContext, LayoutContextProvider };
