import styled from "styled-components";

const SnackbarMainContainer = styled.div`
  display: flex;
  align-items: center;
`;

const SnackbarContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const SnackbarHeader = styled.p`
  font-size: 15px;
  font-weight: 600;
  color: ${({ theme }) => theme.colorsPalette.black.default};
  margin: 0 0 5px 0;
`;

const SnackbarParagraph = styled.p`
  font-size: 14px;
  color: ${({ theme }) => theme.colorsPalette.gray.default};
  margin: 0;
`;

export {
  SnackbarMainContainer,
  SnackbarContentContainer,
  SnackbarHeader,
  SnackbarParagraph,
};
