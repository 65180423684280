import { useContext } from 'react';
import { useMutation } from '@apollo/client';
import { useFormik } from 'formik';
import get from 'lodash/get';
import { Button, ImagePicker, TextInput } from 'kosmos-ui';

import { UPDATE_MY_COMPANY_OFFICER_PROFILE_MUTATION } from 'api/graphql/mutations/users/documents';
import { getObjectsDifference } from 'app/helpers/data';
import { AlertsContext } from 'app/context';
import { IMGS_MAX_SIZES_IN_BYTES } from 'app/constants/various';

import { formValidationSchema } from './helpers';
import {
  SectionContentContainer,
  GroupedFieldsContainer,
  ButtonsContainer,
} from '../styles';

function CompanyOfficerProfileSettingsForm({
  profile,
  hideDialog,
}: {
  profile: {
    _id: string;
    email: string;
    firstname?: string;
    lastname?: string;
    avatar?: string;
  };
  hideDialog: () => void;
}) {
  const { showSuccessSnackbar, showErrorSnackbar } = useContext(AlertsContext);

  const [
    updateMyCompanyOfficerProfileMutation,
    { loading: loadingUpdateMyCompanyOfficerProfileMutation },
  ] = useMutation(UPDATE_MY_COMPANY_OFFICER_PROFILE_MUTATION, {
    onCompleted() {
      showSuccessSnackbar({
        title: 'Perfil actualizado',
        message: 'Tu perfil ha sido actualizado correctamente!',
      });

      hideDialog();
    },
    onError(error) {
      showErrorSnackbar({
        title: 'Error actualizando perfil',
        message: get(error, 'graphQLErrors[0].message'),
      });
    },
  });

  const formInitialValues = {
    firstname: profile.firstname || '',
    lastname: profile.lastname || '',
    email: profile.email,
    avatar: profile.avatar || undefined,
  };

  const form = useFormik({
    initialValues: formInitialValues,
    validationSchema: formValidationSchema,
    onSubmit(values) {
      const dataForMutation = getObjectsDifference({
        primaryObject: values,
        secondaryObject: formInitialValues,
      });

      updateMyCompanyOfficerProfileMutation({
        variables: dataForMutation,
      });
    },
  });

  return (
    <SectionContentContainer>
      <div style={{ width: '80%' }}>
        <GroupedFieldsContainer>
          <TextInput
            value={form.values.firstname}
            onChange={form.handleChange}
            label="Nombre"
            id="firstname"
            name="firstname"
            onBlur={form.handleBlur}
            style={{
              root: {
                width: '100%',
                marginRight: 12,
                marginBottom: 32,
              },
            }}
          />

          <TextInput
            value={form.values.lastname}
            onChange={form.handleChange}
            label="Apellido"
            id="lastname"
            name="lastname"
            onBlur={form.handleBlur}
            style={{
              root: {
                width: '100%',
                marginBottom: 32,
              },
            }}
          />
        </GroupedFieldsContainer>

        <TextInput
          value={form.values.email}
          onChange={form.handleChange}
          label="Email"
          id="email"
          name="email"
          onBlur={form.handleBlur}
          style={{
            root: {
              width: '100%',
              marginBottom: 32,
            },
          }}
          error={!!form.errors.email && form.touched.email}
        />

        <h6>Imagen de perfil</h6>

        <ImagePicker
          setUploadImage={(file: any) => form.setFieldValue('avatar', file)}
          prevImage={form.values.avatar || undefined}
          options={{ maxSize: IMGS_MAX_SIZES_IN_BYTES.THUMB }}
          onDropRejected={() => {
            showErrorSnackbar({
              title: 'Imagen excede límite de tamaño',
              message: `Tu imagen debe ser menor a ${
                IMGS_MAX_SIZES_IN_BYTES.THUMB / 1000
              }KB para mantener un óptimo tiempo de carga en tu shop!`,
            });
          }}
        />
      </div>
      <ButtonsContainer>
        <Button
          variant="secondary"
          onClick={hideDialog}
          style={{ marginRight: '15px' }}
        >
          Cancelar
        </Button>

        <Button
          variant="primary"
          onClick={() => form.handleSubmit()}
          loading={loadingUpdateMyCompanyOfficerProfileMutation}
        >
          Guardar
        </Button>
      </ButtonsContainer>
    </SectionContentContainer>
  );
}

export default CompanyOfficerProfileSettingsForm;
