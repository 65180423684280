import styled from 'styled-components';

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  height: 100%;
`;

const Subheader = styled.h2`
  color: ${({ theme }) => theme.colorsPalette.blue.default};
  font-size: ${({ theme }) => theme.fontSizes.regular};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;

const Paragraph = styled.p`
  margin-bottom: 8px;
`;

export { MainContainer, Subheader, Paragraph };
