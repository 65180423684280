import { useHistory } from 'react-router-dom';

import { useAppSection } from 'app/hooks/app';

import { getSidebarIconColors } from './helpers';
import { TSidebarProps } from './types';
import {
  SidebarContainer,
  SidebarSectionContainer,
  SidebarIconLabelContainer,
  SidebarSubsectionContainer,
  Paragraph,
} from './styles';
import { SIDEBAR_SECTIONS } from './constants';

/**
 * Renders app main sidebar.
 * @param activeSection - The app active section.
 * @param setActiveSection - Dispatch function for setting activeSection.
 * @param show - Flag for showing or not the sidebar.
 * @param toggleShowSidebar - Dispatch function for setting show flag.
 */
export default function Sidebar({ show, toggleShowSidebar }: TSidebarProps) {
  const history = useHistory();

  const [activeSection, setActiveSection] = useAppSection();

  return (
    <SidebarContainer show={show}>
      {SIDEBAR_SECTIONS.map(
        ({ _id, route, label, icon: Icon, subsections }) => {
          const hasActiveSubsection = (subsections || []).reduce(
            (acc, subsection) => {
              if (subsection._id === activeSection) return true;

              return acc;
            },
            false
          );

          return (
            <SidebarSectionContainer
              key={_id}
              isSelected={activeSection === _id || hasActiveSubsection}
              subsectionsActive={!!subsections}
              onClick={() => {
                if (route || !hasActiveSubsection) {
                  route && toggleShowSidebar();

                  setActiveSection(_id);

                  route && history.push(route);
                }
              }}
            >
              {typeof label === 'string' ? (
                <SidebarIconLabelContainer>
                  {Icon && (
                    <Icon
                      height="20px"
                      {...getSidebarIconColors(
                        activeSection === _id || hasActiveSubsection
                      )}
                    />
                  )}

                  <Paragraph>{label}</Paragraph>
                </SidebarIconLabelContainer>
              ) : (
                label
              )}

              {subsections &&
                (activeSection === _id || hasActiveSubsection) &&
                subsections.map((subsection) => {
                  return (
                    <SidebarSubsectionContainer
                      key={subsection._id}
                      isSelected={activeSection === subsection._id}
                      onClick={() => {
                        toggleShowSidebar();

                        setActiveSection(subsection._id);

                        history.push(subsection.route);
                      }}
                    >
                      {subsection.label}
                    </SidebarSubsectionContainer>
                  );
                })}
            </SidebarSectionContainer>
          );
        }
      )}
    </SidebarContainer>
  );
}
