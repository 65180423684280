import { ApolloClient, InMemoryCache, from } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { relayStylePagination } from '@apollo/client/utilities';
import { AUTH_TOKEN_KEYS } from 'app/constants/app';

const httpLink = createUploadLink({
  uri: process.env.REACT_APP_GRAPHQL_ENDPOINT,
});

const onErrorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );

  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem(AUTH_TOKEN_KEYS.companyOfficer);

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        getProducts: relayStylePagination([
          'categoryId',
          'merchantId',
          'search',
        ]),
        getCompanyOrders: relayStylePagination(),
        getInvoices: relayStylePagination(['search']),
        getCompanyShoppers: relayStylePagination(['search', 'sortBy']),
      },
    },
    ProductCategory: {
      fields: {
        products: {
          merge(_, incomingCache) {
            return incomingCache;
          },
        },
      },
    },
    ProductMerchant: {
      fields: {
        products: {
          merge(_, incomingCache) {
            return incomingCache;
          },
        },
      },
    },
  },
});

const client = new ApolloClient({
  cache,
  link: from([authLink, onErrorLink, httpLink]),
});

export default client;
