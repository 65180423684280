import dayjs from 'dayjs';
import localization from 'dayjs/locale/es';

import { CHARS_EQUIVALENCE_HASH_TABLE } from 'app/constants/various';

/**
 * Transform any kind of string (with spaces, uppercases and special chars/ symbols) into a URL-format string.
 * @param name - The name that want to be converted
 * @returns The name in URL format (under score for spaces)
 */
export function getUrlFromName(name: string): string {
  const convertedToValidCharsName = [...name].map((nameChar) => {
    return CHARS_EQUIVALENCE_HASH_TABLE[nameChar] || nameChar;
  });

  const transformedName = convertedToValidCharsName
    .join('')
    .replace(/[^-A-Za-z0-9]+/g, '_')
    .toLowerCase();

  return `/${transformedName}`;
}

/**
 * Formats a number by including dots and commas.
 * @param number - Plain-format number that wants to be converted.
 * @returns - The provided number as formatted string.
 */
export function formatNumber(number: number): string {
  const converterRegex = /(\d)(?=(\d{3})+(?!\d))/g;

  const parsedNumber = number.toString();

  return parsedNumber.replace(converterRegex, '$1.');
}

/**
 * Formats a date with ES / DD-MM-YY format.
 * @param date - Date to format.
 * @returns - Formatted date.
 */
export function formatDate(
  date: Date,
  format: string | undefined = 'DD-MM-YY'
): string {
  const formattedDate = dayjs(date).locale('es', localization).format(format);

  return formattedDate;
}

/**
 * Validates if the provided date follows the
 * --- <short weekday>, <numeric day> <short month>. <numeric year> --- format.
 * @param date - String representation of the date that wants to be verified.
 * @returns - True if the provided date is on format, false otherwise.
 */
export function validateDateFormat(date?: string): boolean {
  const validationRegex =
    /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/i;

  return !!(date && validationRegex.test(date));
}

/**
 * Scale a pixel measure based on provided ratio.
 * @param measure - The initial measure to scale (as string in <number>px format).
 * @param ratio - The ratio of conversion (from 0 to 1).
 * @returns The new measure scaled according to the provided ratio (as string in <number>px format).
 */
export function resizePx(measure: string, ratio: number): string {
  const measureMagnitude = parseInt((measure as any).match(/\d+/g)[0]);

  return `${Math.round(measureMagnitude * ratio * 100) / 100}px`;
}

/**
 * Remove falsy values ( "", 0, false, null, undefined ) from a given object.
 * @param object - A scalar-value object that wants to be cleaned.
 * @param exclude - Array of falsy value types to ignore on the cleanup.
 * @returns An object with only the truthly values of the initial object passed as prop.
 */
export function cleanObject(
  object: {
    [key: string]:
      | string
      | number
      | boolean
      | null
      | undefined
      | any[]
      | Record<string, unknown>;
  },
  exclude?: ('string' | 'number' | 'boolean' | 'null' | 'undefined')[]
) {
  return Object.keys(object).reduce((acc, currentKey) => {
    if (
      object[currentKey] ||
      (Array.isArray(object[currentKey]) &&
        (object[currentKey] as []).length > 0) ||
      (exclude && exclude.includes(typeof object[currentKey] as any))
    )
      return { ...acc, [currentKey]: object[currentKey] };

    return acc;
  }, {});
}

/**
 * Determinates if some of the provided set of data has changed with respect to its prev value.
 * @param dataSet - Set containing the data to compare in a two-items array.
 * @returns - Flag indicating if data changed or not.
 */
export function dataHasChanged(dataSet: any) {
  const result = dataSet.reduce((acc: boolean, item: string[]) => {
    const haveNotChanged = item[0] === item[1];

    return acc && haveNotChanged;
  }, true);

  return !result;
}

export function getObjectsDifference({
  primaryObject,
  secondaryObject,
}: {
  primaryObject: Record<string, unknown>;
  secondaryObject: Record<string, unknown>;
}) {
  const primaryObjectKeys = Object.keys(primaryObject);

  return primaryObjectKeys.reduce((acc, key) => {
    //@ts-ignore
    if (primaryObject[key] !== secondaryObject[key])
      //@ts-ignore
      return { ...acc, [key]: primaryObject[key] };

    return acc;
  }, {});
}

/**
 * Finds the most recent date of a given array of dates.
 * @param dates - An array of dates to search in.
 * @returns - The most recent date of all, in dayjs format.
 */
export function getMostRecentDate(dates: Date[]): dayjs.Dayjs | null {
  if (dates.length === 0) return null;

  const maxDates = dates.map((date) => dayjs(date));

  const mostRecentDate = maxDates.reduce((accumulator, date) =>
    date && accumulator.isAfter(date) ? accumulator : date
  );

  return mostRecentDate;
}

/**
 * Capitalizes a given string (makes each word a capital letter).
 * @param text - Text to capitalize.
 */
export function capitalizeText(text: string) {
  const capitalizedText = text.replace(
    /(^\w|\s\w)(\S*)/g,
    (_, m1, m2) => m1.toUpperCase() + m2.toLowerCase()
  );

  return capitalizedText;
}
